.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2B373B; /* Orange-500 */
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 9999;
  }
  
  .cookie-consent-banner p {
    margin: 0 0 10px;
    font-size: 14px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .accept-button, .decline-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .accept-button {
    background-color: #22c55e; /* Green-500 */
    color: white;
  }
  
  .accept-button:hover {
    background-color: #16a34a; /* Green-600 */
  }
  
  .decline-button {
    background-color: #ef4444; /* Red-500 */
    color: white;
  }
  
  .decline-button:hover {
    background-color: #dc2626; /* Red-600 */
  }
  